
import { Options, Vue } from 'vue-class-component'
import Dictionary from '@localizations/aboutMe'
import Timeline from '@components/my-timeline.vue'

@Options({
  components: {
    'my-timeline': Timeline
  },
  i18n: {
    messages: Dictionary
  }
})
export default class About extends Vue {
}
