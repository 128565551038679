export default {
  en: {
    title: {
      hi: "Hi, I'm",
      name: 'Johnathon'
    },
    description: {
      line1: "I'm Johnathon, a full-stack software developer from the U.K.",
      line2: 'In my current employment I work with .NET Core (C#) to create Web Applications with a SQL Server database and Vue.js with Typescript on the front-end. I have previously worked with PHP and JavaScript, but am open to working with any technology.',
      line3: "I have been working full time since University and while I enjoy programming, conversing directly with clients and leading team demos is where I thrive. My career goal for the next 5-10 years is to become a Solutions Architect/Product Manager/Owner; it's my belief the best architects are developers first, as such I am starting my career here to hone my skills and learn from the best.",
      line4: 'This site is an outlet for coding a diverse array of challenges and games. At work I code complex business logic with requirements and edge cases, and while I enjoy it, this is a space for me to experiment with different language features and frivolous side projects. It also provides a means for me to immediately publish them, without having to battle the annoying setup process, which I found to be the main factor in not producing anything previously.'
    }
  }
}
